











































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { RequestPaging, copyObject, timeFormat } from "@/utils/util";
import { orderType } from "@/utils/type";
import { apiorderFlowChartLists } from "@/api/order/order";
import LsPagination from "@/components/ls-pagination.vue";
import DatePicker from "@/components/date-picker.vue";
import { apiShopLists } from "@/api/shop";
@Component({
    components: {
        LsPagination,
        DatePicker,
    },
})
export default class settlement_order extends Vue {
    settlementVisible = false;
    type = 1;
    timeFormat = timeFormat;
    info: any = {};
    rows: any = {};

    searchObj: any = {
        order_sn: "",
        after_sale_sn: "",
        shop_id: "",
        start_time: "",
        end_time: "",
    };
    orderType = orderType;
    shopLists = [];
    pager: RequestPaging = new RequestPaging();

    // 获取商城列表
    getShopLists(): void {
        apiShopLists({ page_type: 0, app_id: 11 }).then((res) => {
            this.shopLists = res.lists;
        });
    }
    getList(page?: number) {
        page && (this.pager.page = page);

        this.pager
            .request({
                callback: apiorderFlowChartLists,
                params: {
                    ...this.searchObj,
                },
            })
            .then((res: any) => {});
    }
    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, "");
        });
        this.getList();
    }

    created() {
        this.getShopLists();
        const today = new Date();
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        this.searchObj.start_time = startOfMonth;
        this.searchObj.end_time = today;
        this.getList();
    }
}
